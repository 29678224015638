<template>
  <ul class="list list--numbered-circle" :class="'list--numbered-circle-' + theme">
    <li class="mb-4 mb-lg-5" v-for="(list, l) in list" :key="'option-list-' + l">
      <i>{{ l + 1 }}</i>
      <div class="list__wrapper">
        <p class="text fs-14 fs-md-16 fw-400 lh-20">
          <span v-for="(item, t) in list.item" :key="'list-item-' + t" :class="setColor(item, theme)">
            {{ item.text }}
          </span>
        </p>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  props: ['theme', 'list'],
  methods: {
    setColor (item, theme) {
      let result = 'tc-silver'
      if ('highlighted' in item) result = 'tc-' + theme
      return result
    }
  }
}
</script>
