<template>
  <div class="page page--deposit">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2">
            <p class="text tc-blue fs-xl-20 fw-500 ta-center mb-3 mb-md-4">{{ cc.sec1.text[0] }}</p>
            <p class="text tc-smoke fs-22 fs-sm-26 fs-lg-30 fw-500 ta-center mb-3 mb-md-4">{{ cc.sec1.preTitle }}</p>
            <h1 class="title title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center mb-3 mb-md-4">{{ cc.sec1.title }}</h1>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center mb-3 mb-md-4">{{ cc.sec1.text[1] }}</p>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center">{{ cc.sec1.text[2] }}</p>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center">{{ cc.sec1.text[3] }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="control mx-auto">
              <div class="control__wrapper">
                <span class="control__tape" id="tape"></span>
                <div class="control__item" v-for="(item, i) in cc.sec2.text" :key="'control-' + i" :id="'control-' + i">
                  <p class="control__text" @click="goToSlide(i)" :class="{'active': current === i}">{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-3" data-sec="3">
      <div class="slider">
        <div class="slider__container" id="slider-container">
          <div class="slider__section" v-for="(slide, s) in cc.sec3.slider" :key="'slider-section-' + s" :class="{'active': current === s}" :id="'slide-' + s">
            <div class="container">
              <div class="row mb-3 mb-md-4">
                <div class="col-12">
                  <p class="text fs-md-20 fw-500 ta-center ta-md-left" :class="'tc-' + slide.theme">{{ slide.text[0] }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-xl-6 pe-xl-4 mb-5 mb-md-0">
                  <h2 class="title fs-32 fs-sm-50 fs-md-42 fw-600 lh-13 ta-center ta-md-left mb-4 mb-md-5"
                      :class="[{'fs-xl-58': ($i18n.locale !== 'ru' && $i18n.locale !== 'es')},
                               {'fs-xl-44': $i18n.locale === 'ru' || $i18n.locale === 'es'}]">
                    {{ slide.title }}
                  </h2>
                  <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-5 pb-lg-4">{{ slide.text[1] }}</p>
                  <div class="img img--deposit-sec3" :data-slide="s + 1">
                    <img :src="'/img/pages/accounts/deposit/sec3/img-' + (s + 1) + '.png'" alt="buying">
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6 ps-xl-4">
                  <comp-list-numbered :theme="slide.theme" :list="slide.list"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--gray" id="sec-4" data-sec="4">
      <comp-contacts/>
    </section>
  </div>
</template>
<script>
import CompContacts from '@/components/Contacts'
import CompListNumbered from '@/components/lists/ListNumberedCircle'
export default {
  name: 'Deposit',
  data: () => ({
    current: 0
  }),
  components: {
    CompContacts, CompListNumbered
  },
  methods: {
    goToSlide (i) {
      if (this.current !== i) this.current = i
      this.changeSlide(i)
      this.changeTape(i)
    },
    changeSlide (i) {
      const container = document.getElementById('slider-container')
      const offset = 50 * i
      container.style.transform = 'translateX(-' + offset + '%)'
      container.style.height = this.containerHeight + 'px'
    },
    changeTape (i = this.current) {
      const tape = document.getElementById('tape')
      const translate = i !== 0 ? this.tapeOffset() + 5 : 5
      setTimeout(() => {
        tape.style.width = this.tapeWidth() + 'px'
        tape.style.transform = 'translate(' + translate + 'px, 5px)'
      }, 0)
    },
    tapeWidth: function () {
      const element = document.getElementById('control-' + this.current)
      return element.clientWidth
    },
    tapeOffset: function () {
      const element = document.getElementById('control-0')
      return element.clientWidth
    }
  },
  computed: {
    containerHeight: function () {
      const element = document.getElementById('slide-' + this.current)
      return element.clientHeight
    },
    cc () {
      return this.$t('pages.accounts.deposit')
    }
  },
  watch: {
    '$i18n.locale' () {
      setTimeout(() => {
        this.changeTape()
      }, 600)
    },
    '$store.state.ww' () {
      setTimeout(() => {
        this.changeTape()
      }, 600)
    }
  },
  metaInfo () {
    const meta = this.$t('meta.accounts.child.deposit')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.changeSlide(0)
      this.changeTape(0)
    }, 100)
  }
}
</script>
